import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


// https://github.com/DZuz14/react-hooks-image-slider/blob/master/src/components/Slider.js

const MAX_WIDTH = '2560px';
const btnSize = 24;
const cutSize = 3;


export const Frame = styled.div`
  ${props => props.container && css`
    display: block;
    position: relative;
  `}
  ${props => props.frame && css`
    /* dispa */
  `}
  ${props => props.frame_over && css`
    position: absolute;
    top: 0;
  `}
`

export const Image = styled(GatsbyImage)`
  max-width: ${MAX_WIDTH};
  max-height: 480px;
  min-height: 160px;
  width: auto;
  margin: auto;

  @media ${({ theme }) => theme.device.mobile}{
    min-height: 160px;
  }
  display: block;
  
  ${props => props.visible && css`
    display: none;
  `};
`;

export const Navigation = styled.div`
  position: absolute;
  z-index: 10;
  
  ${props => props.location === "left" && css`
    left: 10px;
    top: 50%;
    margin-top: -16px;
    display: none;
  `}

  ${props => props.location === "right" && css`
    right: 10px;
    top: 50%;
    margin-top: -16px;
    display: none;
  `}

  ${props => props.location === "bottom" && css`
    bottom: 10px;
    left: 50%;
    margin-left: -28px;
    @media ${({ theme }) => theme.device.mobile}{
      margin-left: -32px;
      bottom: 2px;
    }
  `}
`;

export const Wrap = styled.div`
  text-align: center;
  position: relative;

  &:hover{
    ${Navigation}{
      display: block;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  z-index: 1;
  background-color: ${({ theme }) => theme.thirdiary.main};

  width: ${btnSize - cutSize * 2}px;
  height: ${btnSize}px;
  text-align: center;
  padding: 0;

  svg{
    margin-top: 3px;
    fill: #fff;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  &:hover{
    background-color: ${({ theme }) => theme.thirdiary.main}};
    
    &::before{
      border-left: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    }
    &::after{
      border-right: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    }
  }

  ${props => props.right && css`
    right: -${cutSize}px;
  `}

  ${props => props.left && css`
    left: -${cutSize}px;
    svg {
      margin-left: -6px;
    }
  `}
`;

export const Dot = styled.button`
  cursor: pointer;
  position: relative;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  padding:0;
  line-height: 0;
  margin: 0 5px;
  background: #ffffff;
  border: 0;

  @media ${({ theme }) => theme.device.mobile}{
    margin: 0 8px;
  }


  &:hover{
    background: ${({ theme }) => theme.thirdiary.light};
  }

  ${props => props.isActive && css`
    background: ${({ theme }) => theme.thirdiary.main};
  `}
`
