import { css } from 'styled-components';


export const COMMON_COLORS = [
  'green',
  'blue',
  'yellow',
  'red',
  'orange',
  'amber',
  'purple',
  'teal',
  'white',
];

export const HIEARARCHY_COLORS = [
  'primary',
  'secondary',
  'thirdiary',
];

export const getAngleBySize = ({ angle }) => css`
  background: ${({ theme }) => theme.background.paper};
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: calc(-${angle} + 0.5px);
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-left: ${angle} solid #ffffff;
    border-bottom: ${angle} solid transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: calc(-${angle} + 0.5px);
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-right: ${angle} solid #ffffff;
    border-bottom: ${angle} solid transparent;
  }
`;

export const getAngleByCommonColor = ({ theme, color, disableHover = false }) => css`
  background: ${theme.common[color]};
  color: ${({ theme }) => theme.text.white};

  ${({ color }) => ["white"].includes(color) && css`
    color: ${({ theme }) => theme.primary.main};
    fill: ${({ theme }) => theme.primary.main};
  `}

  &::before { border-left-color: ${theme.common[color]}; }
  &::after { border-right-color: ${theme.common[color]}; }

  ${disableHover !== true && css`
    &:hover{
      background: ${theme.common.hover[color]};

      ${({ color }) => ["white"].includes(color) && css`
        color: ${({ theme }) => theme.primary.dark};
        fill: ${({ theme }) => theme.primary.dark};
      `}
      
      &::before { border-left-color: ${theme.common.hover[color]}; }
      &::after { border-right-color: ${theme.common.hover[color]}; }
    }
  `}
`;

export const getAngleByHierarchyColor = ({ theme, color, disableHover = false }) => css`
  background: ${theme[color].main};
  color: ${({ theme }) => theme.text.primary};

  ${({ color }) => ["primary", "thirdiary"].includes(color) && css`
    color: ${({ theme }) => theme.text.white};
    fill: ${({ theme }) => theme.text.white};
  `}

  ${({ color }) => ["secondary"].includes(color) && css`
    fill: ${({ theme }) => theme.secondary.contrastText};
  `}

  &::before { border-left-color: ${theme[color].main}; }
  &::after { border-right-color: ${theme[color].main}; }

  ${disableHover !== true && css`
    &:hover{
      background: ${theme[color].dark};
      &::before { border-left-color: ${theme[color].dark}; }
      &::after { border-right-color: ${theme[color].dark}; }
    }
  `}
`;
