import { css } from 'styled-components';


export const COMMON_COLORS = [
  'green',
  'blue',
  'yellow',
  'red',
  'orange',
  'amber',
  'purple',
  'white',
];

export const HIEARARCHY_COLORS = [
  'primary',
  'secondary',
  'thirdiary',
];

export const STYLE_COLORS = [
  'background',
  'background-dark',
  'background-dark-2',
]

export const getAngleBySize = ({ angle }) => css`
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-left: ${angle} solid #ffffff;
    border-bottom: ${angle} solid transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-right: ${angle} solid #ffffff;
    border-bottom: ${angle} solid transparent;
  }
`;

export const getAngleByCommonColor = ({ theme, color }) => css`
  background: ${theme.common[color]};
  &::before { border-left-color: ${theme.common[color]}; }
  &::after { border-right-color: ${theme.common[color]}; }
`;

export const getAngleByHierarchyColor = ({ theme, color }) => css`
  background: ${theme[color].main};
  &::before {
    border-left-color: ${theme[color].main};
  }
  &::after {
    border-right-color: ${theme[color].main};
  }
`;

export const getAngleByStyleColor = ({ theme, color }) => css`

  ${({ color }) => color === "background" && css`
    background: ${theme.background.default};
    &::before { border-left-color: ${theme.background.default};}
    &::after { border-right-color: ${theme.background.default};}
  `}

  ${({ color }) => color === "background-dark" && css`
    background: ${theme.background.dark};
    &::before { border-left-color: ${theme.background.dark};}
    &::after { border-right-color: ${theme.background.dark};}
  `}

  ${({ color }) => color === "background-dark-2" && css`
    background: ${theme.background.dark}55;
    &::before { border-left-color: ${theme.background.dark}55;}
    &::after { border-right-color: ${theme.background.dark}55;}
  `}
`;
