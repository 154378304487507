import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  position: relative;
`;

export const ToggleButton = styled.button`
  position: relative;
  display: block;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0 8px;

  &:focus{
    outline: none;
  }  
`;

export const AnchorPoint = styled.div`
  position: absolute;
  bottom: 0;
  display: none;

  ${props => props.active && css`
    display: block;
  `}

  ${props => props.nature === "menu" && css`
    right: 0;
    left: auto;

    @media ${({ theme }) => theme.device.mobile}{
      left: 0;
      right: auto;
    }
  `}

  ${props => props.nature === "basket" && css`
    right: 0;
    left: auto;
  `}
`;

export const Anchor = styled.div`  
  position: relative;
  min-width: 240px;

  ${props => props.nature === "menu" && css`
    min-width: 240px;

    @media ${({ theme }) => theme.device.phone}{
      min-width: calc(100vw - 8px);
    }
  `}

  ${props => props.nature === "basket" && css`
    min-width: 294px;

    @media ${({ theme }) => theme.device.phone}{
      min-width: calc(100vw - 8px);
    }
  `}
`;

const containerCut = 2;

export const AnchorWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

  @media ${({ theme }) => theme.device.phone}{
  left: -2px;
  right: -2px;
  }
`;

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background.paper};

  ${props => props.background === "default" && css`
    background: ${({ theme }) => theme.background.default};
    padding: 2px 0;
    margin: -2px 0;
    /* z-index: 2; */

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -${containerCut}px;
      bottom: 0;

      border-top: ${containerCut}px solid transparent;
      border-left: ${containerCut}px solid ${({ theme }) => theme.background.default};
      border-bottom: ${containerCut}px solid transparent;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -${containerCut}px;
      bottom: 0;
      
      border-top: ${containerCut}px solid transparent;
      border-right: ${containerCut}px solid ${({ theme }) => theme.background.default};
      border-bottom: ${containerCut}px solid transparent;
      z-index: 1;
    } 
  `}
`;

