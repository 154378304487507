/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 
 export const SEO = ({ description, lang, meta, title, canonical, featured_image }) => {
   const { site, defaultFeaturedImage } = useStaticQuery(
     graphql`
        query {
          site {
            siteMetadata {
              siteUrl
              title
              description
            }
          }
          defaultFeaturedImage: file(relativePath: { eq: "default-image.jpg" }) {
            childImageSharp {
              fixed(width: 1200, height: 627) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `
   )
 
   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
   const featuredImage = featured_image?.images.fallback.src || defaultFeaturedImage?.childImageSharp.fixed.src;
   const defaultDomain = site.siteMetadata?.siteUrl
   // const keywords = site.siteMetadata?.keywords
   //  console.log(featuredImage)
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       titleTemplate={defaultTitle ? `%s ` : defaultTitle}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           itemprop: 'image',
           content: `${defaultDomain}${featuredImage}`,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: 'og:url',
           content: canonical,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:card`,
           content: `summary_large_image`,
         },
         {
           name: `twitter:title`,
           content: title,
         },
         {
           name: `twitter:image`,
           content: `${defaultDomain}${featuredImage}`,
         },
         {
           name: `robots`,
           content: `follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large`,
         },
         {
 
         },
       ].concat(meta)}
     >
       <link rel="canonical" href={`${defaultDomain}${canonical}`} />
     </Helmet>
   )
 }
 
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   canonical: '/',
 }
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default SEO
 
 
 