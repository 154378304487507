import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


const cutSize = 4;
const cutSize2 = 4;


export const FullScreenShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.5);

  @media ${({theme}) => theme.device.mobile}{
    background-color: rgba(0, 0, 0, .95);
  }
`

export const FullScreen = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;

export const FullScreenWrap = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 20px;
    
`;

export const FullScreenContainer = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.common.black};
  max-width: 920px;
  margin: 0 auto;
  margin-top: 0;
  border: 1px solid #000;

  @media ${({theme}) => theme.device.phone}{
    margin-top: 0;
  }
`;

export const FullScreenImage = styled(GatsbyImage)`
  max-height: calc(100vh - 40px);
`;


const btnSize2 = 34;

export const Navigation = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  cursor: pointer;
  margin-top: -14px;
  padding: 0;
  
  z-index: 1;

  ${props => props.right && css`
    right: -${cutSize2}px;
  `}

  ${props => props.left && css`
    left: -${cutSize2}px;
  `}

  ${props => props.close && css`
    right: -${cutSize2}px;
    top: -${cutSize2 * 2}px;
    margin-top: 0;
  `}
`;

export const Youtube = styled.iframe`
  width: 918px;
  height: 514px;
  margin: 0 auto;
  border: 0;

  @media ${({theme}) => theme.device.tablet}{
    width: 520px;
    height: 314px;
  }

  @media ${({theme}) => theme.device.mobile}{
    width: 330px;
    height: 240px;
  }

  @media only screen and (max-width: 350px){
    width: 280px;
    height: 240px;
  }
`;