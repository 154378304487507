import React from "react";
import { Button } from '../../ui-components';
import {
  FullScreenShadow,
  FullScreen,
  FullScreenWrap,
  FullScreenContainer,
  FullScreenImage,
  Navigation,
  Youtube,
} from './gallery-fullscreen.styled';



const GalleryFullScreen = ({ data, onNext, onPrev, onClose, forcedType, disableNavigation = false }) => (
  <FullScreenShadow>
    <FullScreen>
      <FullScreenWrap>
        <FullScreenContainer>
          <Navigation close>
            <Button
              type="button"
              size="icon"
              icon="close-outline"
              color="thirdiary"
              onClick={onClose}
              inline
              nomargin
            />
          </Navigation>
          {!disableNavigation ? (
            <Navigation left>
              <Button
                type="button"
                size="icon"
                icon="caret-backward-sharp"
                color="thirdiary"
                onClick={onPrev}
                inline
                nomargin
              />
            </Navigation>
          ) : null}
          {!disableNavigation ? (
            <Navigation right>
              <Button
                type="button"
                size="icon"
                icon="caret-forward-sharp"
                color="thirdiary"
                onClick={onNext}
                inline
                nomargin
              />
            </Navigation>
          ) : null}
          {
            data.type === "youtube" || forcedType === "youtube"
              ?
              <Youtube
                src={`${data.youtube}?autoplay=1`}
                title="BeTRITON Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              : <FullScreenImage
                image={data.image}
                alt="Gallery Image"
              />
          }
        </FullScreenContainer>
      </FullScreenWrap>
    </FullScreen>
  </FullScreenShadow>
)

export default GalleryFullScreen;
