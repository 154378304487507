import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


// https://github.com/DZuz14/react-hooks-image-slider/blob/master/src/components/Slider.js

const MAX_WIDTH = '2560px';

export const Frame = styled.div`

  ${props => props.wrapper && css`
    position: relative;
  `}

  ${props => props.container && css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  `}

  ${props => props.background && css`
  `}

  ${props => props.text && css`
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media only screen and (min-width: 2000px){
      margin-top: 20vh;
    }

    @media ${({ theme }) => theme.device.desktop}{
      margin-top: 10vh;
    }

    @media ${({ theme }) => theme.device.tablet}{
      margin-top: 80px;
    }

    @media ${({ theme }) => theme.device.mobile}{
      margin-top: 80px;
      margin-left: 8px;
      margin-right: 8px;
    }

    @media ${({ theme }) => theme.device.phone}{
      margin-top: 20px;
      margin-left: 8px;
      margin-right: 8px;
    }
    
  `}
`;

export const Image = styled(GatsbyImage)`
  /* min-height: 800px; */
  /* max-width: ${MAX_WIDTH}; */
  max-height: 85vh;
  width: auto;
  margin: auto;

  @media only screen and (min-width: 2000px){
    /* max-height: 1200px; */
  } 
  
  @media ${({ theme }) => theme.device.mobile}{
    min-height: 460px;
  }

  display: block;
  
  ${props => props.visible && css`
    display: none;
  `};
`;

export const Wrap = styled.div`
  text-align: center;
  position: relative;
`;

export const Title = styled.h1`
  font-family: "Quantico", sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 42px;
  line-height: 52px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.primary};
  margin: 0 0 8px 0;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.3);
  display: inline-block;

  @media ${({ theme }) => theme.device.mobile}{
    font-size: 36px;
    line-height: 41px;
  }
`

export const Description = styled.div`
  color: ${({ theme }) => theme.text.white};
  background: ${({ theme }) => theme.primary.main};
  display: inline-block;
  padding: 8px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  /* margin: 0 8px; */
  margin-bottom: 20px;

  @media only screen and (min-width: 1800px){
    margin-bottom: 20px;

  }

  @media ${({ theme }) => theme.device.tablet}{
    background: ${({ theme }) => theme.primary.main}cc;
    max-width: 620px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  @media ${({ theme }) => theme.device.mobile}{
    background: ${({ theme }) => theme.primary.main}cc;
    max-width: 620px;
    margin-bottom: 20px;
    font-size: 16px;

  }
  
`

export const PlayButton = styled.button`
  border: 0;
  background: ${({ theme }) => theme.thirdiary.main};
  border-radius: 50%;
  height: 80px;
  width: 80px;
  
  padding-left: 12px;
  fill: ${({ theme }) => theme.text.white};
  
  &:hover{
    background: ${({ theme }) => theme.thirdiary.dark};
  }

  @media ${({ theme }) => theme.device.mobile}{
    width: 60px;
    height: 60px;
    padding-left: 10px;
  }
`

export const Video = styled.video`
  max-height: 85vh;
  width: 100%;
  margin: auto;
  /* border: 1px solid red; */

`