import React, { useState } from "react";
import {
  useStaticQuery,
  graphql,
} from 'gatsby';
import { Icon } from "../../ui-components";
import {
  Image,
  Wrap,
  Title,
  Description,
  Frame,
  // PlayButton,
  Video,
} from './hero.styled';
import GalleryFullScreen from "../../components/gallery/gallery-fullscreen";
import HeroVideoMP4 from "../../assets/video/hero_video_large.mp4";
import CrowdFunding from "../../components/crowdfunding/crowdfunding"

// https://github.com/DZuz14/react-hooks-image-slider/blob/master/src/components/Slider.js


// THERE WAS IMAGE FLICKERING ON TRANSITION THEREFORE I CHOOSE TO HAVE IMAGE LAYERS (2nd layer is on top of the other)

const HeroImages = ({ name }) => {
  const { heroImage_0 } = useStaticQuery(
    graphql`
    query {
      heroImage_0: file(relativePath: {eq: "betriton-hero.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 2560,
            quality: 100
            placeholder: BLURRED
            breakpoints: [820, 360]
          )
        }
      }
    }
  `)
  return (
    <Image
      image={heroImage_0.childImageSharp.gatsbyImageData}
      alt="BeTRITON Boat"
    />
  )
};


const HeroVideo = () => {
  // const { heroVideo } = useStaticQuery(
  //   graphql`
  //   query {
  //     heroVideo: file(relativePath: {eq: "assets/BeTriton_Website_HeroVideo_Large.mp4"}) {
  //       absolutePath
  //     }
  //   }
  // `)
  // alert(heroVideo)
  return (
    <Video muted video autoPlay="autoplay" loop="loop" defaultMuted playsinline oncontextmenu="return false;" preload="auto" id="myVideo">
      <source src={HeroVideoMP4} type="video/mp4" />
      {/* <source src={heroVideo.absolutePath} type="video/mp4" /> */}
    </Video>
  )

}


export const Hero = ({ title, description, video }) => {
  const [fullScreenIndex, setFullScreenIndex] = useState(null);
  const data = [
    {
      "type": "youtube",
      "youtube": "https://www.youtube.com/embed/bHpCEUSWt7Q"
    }
  ]
  const fullScreenData = fullScreenIndex !== null ? data[fullScreenIndex] : null;

  const enterFullScreen = () => {
    setFullScreenIndex(0);
  }

  const handleClose = (event) => {
    event.preventDefault();
    setFullScreenIndex(null);
  }
  return (
    <>
      {fullScreenData
        ? (
          <GalleryFullScreen
            data={{
              type: fullScreenData.type,
              youtube: fullScreenData.youtube,
            }}
            disableNavigation
            onNext={() => { }}
            onPrev={() => { }}
            onClose={handleClose}
            forcedType="youtube"
          />
        )
        : ''}
      <Wrap>

        <Frame wrapper>
          <Frame background>

            {/* <HeroVideo /> */}
            <HeroImages name={`heroImage_0`} />

          </Frame>
          <Frame container>
            <Frame text>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <br />
              {/* <div>
                <PlayButton onClick={enterFullScreen}>
                  <Icon
                    name="caret-forward-sharp"
                    size={50}
                    noFill
                  />
                </PlayButton>
              </div> */}
            </Frame>
          </Frame>
        </Frame>
      </Wrap>
    </>
  )
}

export default Hero;
