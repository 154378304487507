import styled, { keyframes, css } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


const gradient = keyframes`
  0%   { background-position: 0 0; }
  100% { background-position: -200% 0; }
`;

// .animated-gradient {

// }

// @keyframes gradient { 
// }

export const LoadingBar = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: repeating-linear-gradient(to right, #177AF5 0%,  #FB7124 30%, #177AF5 50%,  #177AF5 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: ${gradient} 2s infinite, ${fadeIn} 0.4s linear;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  transition: visibility 0.3s linear;
`


export const LoadingSpinner = styled.div`
  animation: ${rotate360} .8s linear infinite;
  transform: translateZ(0);
  border: 2px solid ${({ theme }) => theme.background.paper};
  border-left: 2px solid ${({ theme }) => theme.primary.main};
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: auto;

  ${props => props.size === "medium" && css`
    border: 2px solid ${({ theme }) => theme.background.paper};
    border-left: 2px solid ${({ theme }) => theme.primary.main};

    width: 36px;
    height: 36px;
  `}

  ${props => props.size === "large" && css`
    border: 3px solid ${({ theme }) => theme.background.paper};
    border-left: 3px solid ${({ theme }) => theme.primary.main};

    width: 100px;
    height: 100px;
  `}

  ${props => props.size === "extra-large" && css`
    border: 4px solid ${({ theme }) => theme.background.paper};
    border-left: 4px solid ${({ theme }) => theme.primary.main};

    width: 160px;
    height: 160px;
  `}
`;
