import styled  from "styled-components";


export const Contanier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 16px;
`;

export const SocialLink = styled.a`
padding: 0 4px;

svg{
  fill: ${({theme}) => theme.primary.main};

&:hover{
  fill: ${({theme}) => theme.primary.dark};

  }
}
`;

export const SocialButton = styled.button`
padding: 0 4px;
cursor: pointer;
border: 0;
background: transparent;

svg{
  fill: ${({theme}) => theme.primary.main};
  &:hover{
    fill: ${({theme}) => theme.primary.dark};
  }
}
`;

export const SocialText = styled.div`
font-size: 11px;
text-transform: uppercase;
color: ${({theme}) => theme.text.secondary};

@media only screen and (max-width: 500px){
  display: none;
}
`