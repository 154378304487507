import React from "react";
import styled, { css } from "styled-components";


const angle = '3px';

export const Table = styled.div`
  margin: 16px 0;
  display: table;
  width: 100%;
`;


const ColContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  position: relative;

  ${props => props.align === "left" && css`
    text-align: left;
  `};

  ${props => props.align === "center" && css`
    text-align: center;
  `};

  ${props => props.align === "right" && css`
    text-align: right;
  `};
`;


const ColStyle = styled.div`
  position: absolute;
  top: 1px;
  left: ${angle};
  right: calc(${angle} + 1px);
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  background: ${({ theme }) => theme.shades.main};
  color: ${({ theme }) => theme.text.primary};

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-left: ${angle} solid ${({ theme }) => theme.shades.main};
    border-bottom: ${angle} solid transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-right: ${angle} solid ${({ theme }) => theme.shades.main};
    border-bottom: ${angle} solid transparent;
  }
`;

const ColWrap = styled.div`
  position: relative;
  /* z-index: 1; */
  padding: 12px 12px;

  ${props => props.nomargin && css`
    padding: 2px 0;
  `}
`;

export const Head = styled.div`
  display: table-row;
  font-size: 13px;
  line-height: 15px;

  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.white} !important;

  ${ColStyle}{
    background: ${({ theme }) => theme.primary.main};
    &::before { border-left-color: ${({ theme }) => theme.primary.main}; }
    &::after { border-right-color: ${({ theme }) => theme.primary.main}; }
  }

  ${ColWrap}{
    padding: 6px 12px;
  }
`;

export const Footer = styled.div`
  display: table-row;
  font-size: 13px;
  line-height: 15px;

  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.main};

  ${ColStyle}{
    background: ${({ theme }) => theme.background.paper};
    &::before { border-left-color: ${({ theme }) => theme.common.white}; }
    &::after { border-right-color: ${({ theme }) => theme.common.white}; }
  }

  ${ColWrap}{
    padding: 8px 8px;
  }
`;

export const Row = styled.div`
  display: table-row;
  font-size: 14px;
  line-height: 17px;

  &:hover{
    ${ColStyle}{
      background: ${({ theme }) => theme.shades.light};

      &::before { border-left-color: ${({ theme }) => theme.shades.light}; }
      &::after { border-right-color: ${({ theme }) => theme.shades.light}; }
    }
  }
`;


export const Col = ({ children, nomargin, clear = false, ...rest }) => (
  <ColContainer {...rest}>
    {clear ? null : <ColStyle />}
    <ColWrap nomargin={nomargin}>
      {children}
    </ColWrap>
  </ColContainer>
);

