import React from "react";
import styled, { css } from "styled-components";
import { Icon } from '../';
import AuthSubscribeContainer from '../../containers/auth-subsbribe.container';
import LogoImage from '../../assets/img/BeTRITON-white.png';
// import ZeltiniLogo from '../../assets/img/zeltini-logo-white-2.png';
import { beLandingURL } from '../../utils';



const Wrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.primary.dark};
  padding: 0;
`;

const Container = styled.div`
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: left;
  display: block;
  position: relative;
  padding: 0;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.device.mobile}{
    margin: 0 ${({ theme }) => theme.size.sides}px;
    justify-content: center;
  }

  ${props => props.sub && css`
    @media only screen and (max-width: 620px){
      display: block;
    }
  `}
`;

const FlexItem = styled.div`
  flex: ${(props) => props.size || 1};
  padding: 8px;


  ${props => props.logo && css`
    flex: 2;
    text-align: center;
    margin-left: -20px;
    padding-right: 20px;

    @media ${({ theme }) => theme.device.mobile}{
      flex: 100%;
      margin-left: 0;
      padding-right: 0;
      padding: 16px 0;
    }
  `}

  ${props => props.product && css`
    @media ${({ theme }) => theme.device.mobile}{
      flex: 33%;
      max-width: 210px;
      text-align: center;
    }

    @media ${({ theme }) => theme.device.phone}{
      flex: 100%;
      padding-bottom: 0;
    }
  `}

  ${props => props.support && css`
    @media ${({ theme }) => theme.device.mobile}{
      flex: 33%;
      max-width: 210px;
      text-align: center;
    }

    @media ${({ theme }) => theme.device.phone}{
      flex: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  `}

  ${props => props.social && css`
    svg{
      fill: ${({ theme }) => theme.primary.main};
    }

    @media ${({ theme }) => theme.device.mobile}{
      flex: 33%;
      max-width: 210px;
      text-align: center;
    }

    @media ${({ theme }) => theme.device.phone}{
      flex: 100%;
      padding-top: 0;
    }
  `}

  ${props => props.partner && css`
    padding: 16px;
    
    display: flex;
    justify-content: flex-end;
    
    @media ${({ theme }) => theme.device.mobile}{
      display: none;
    }
  `}

  ${props => props.terms && css`
    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
      padding: 8px;
    }
  `}

  ${props => props.language && css`
    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
      padding: 8px;
    }
  `}

  ${props => props.copy && css`
    text-align: right;

    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
      padding:0;
    }
  `}
`;

const FooterHome = styled.a`
  @media ${({ theme }) => theme.device.mobile}{
    display: none;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 38px;
  margin: 16px 8px;
`;

const Heading = styled.h4`
  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;

  @media ${({ theme }) => theme.device.mobile}{
    margin-top: 8px;
  }
`;

const Link = styled.a`
  display: block;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 6px;
  display: ${(props) => props.display || `block`};
  color: ${({ theme }) => theme.primary.main};

  & > svg {
    margin-right: 6px;
  }
  
  &:hover{
    text-decoration: underline;
  }
`;

const SubContainer = styled.div`
  position: relative;
  padding: 0 12px 6px 12px;
  color: ${({ theme }) => theme.primary.light};

  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;

`;


const SubLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 20px;
  text-decoration: none;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.primary.main};
  display: ${(props) => props.display || `block`};

  & > svg {
    margin-right: 6px;
  }
  
  &:hover{
    text-decoration: underline;
  }
`;

// const ZeltiniLink = styled.a`

// `

// const Zeltini = styled.img`
//   position: relative;
//   width: auto;
//   max-height: 100px;

//   @media ${({ theme }) => theme.device.mobile}{
//     margin: auto;
//   }
// `;

const Footer = () => (
  <Wrapper>
    <Container>
      <Flex>
        <FlexItem logo>
          <FooterHome href={beLandingURL}>
            <Logo src={LogoImage} alt="BeTRITON" />
          </FooterHome>
          <AuthSubscribeContainer />

          {/* <ButtonLink
            href="https://ej.uz/newsletterZTriton"
            color="thirdiary"
            size="small"
            target="_blank"
          >
            Subscribe to News
          </ButtonLink> */}
        </FlexItem>
        <FlexItem product>
          <Heading>Product</Heading>
          <Link href={`${beLandingURL}/`}>Home</Link>
          <Link href={`${beLandingURL}/media-coverage/`}>Media Coverage</Link>
          <Link href={`${beLandingURL}/story/`}>Story</Link>
          <Link href={`${beLandingURL}/team/`}>Team</Link>
        </FlexItem>
        <FlexItem support>
          <Heading>Support</Heading>
          <Link href={`${beLandingURL}/faq/`}>FAQ</Link>
          <Link href={`${beLandingURL}/contacts/`}>Contacts</Link>
        </FlexItem>
        <FlexItem social>
          <Heading>Social</Heading>
          <Link href="https://www.instagram.com/betriton_amphibian/" target="_blank">
            <Icon
              name="instagram"
              width={14}
              height={14}
              noFill
            />Instagram
          </Link>
          <Link href="https://www.youtube.com/channel/UCISjFLcZXMa9n5JFUwBKjhw/featured?view_as=subscriber" target="_blank">
            <Icon
              name="youtube"
              width={14}
              height={14}
              noFill
            />Youtube
          </Link>
          <Link href="https://www.facebook.com/betriton.amphibian/" target="_blank">
            <Icon
              name="facebook"
              width={14}
              height={14}
              noFill
            />Facebook
          </Link>
        </FlexItem>
        {/* <FlexItem partner>
          <ZeltiniLink href="https://zeltini.com" target="_blank">
            <Zeltini src={ZeltiniLogo} alt="Zeltini" />
          </ZeltiniLink>
        </FlexItem> */}
      </Flex>
      <SubContainer>
        <Flex sub>
          <FlexItem language>
            <SubLink display="inline-block" href={`${beLandingURL}/lv/`}>LV</SubLink>
            &nbsp;{'|'}&nbsp;
            <SubLink display="inline-block" href={`${beLandingURL}/`}>EN</SubLink>
          </FlexItem>
          <FlexItem terms>
            <SubLink display="inline-block" href={`${beLandingURL}/terms/`}>Terms &amp; Conditions</SubLink>
            &nbsp;{'  |  '}&nbsp;
            <SubLink display="inline-block" href={`${beLandingURL}/privacy/`}>Privacy Policy</SubLink>
          </FlexItem>
          <FlexItem copy>
            <SubLink href="https://betriton.com" target="_blank">
              &copy;{new Date().getFullYear()} BeTRITON All rights reserved
            </SubLink>
          </FlexItem>
        </Flex>
      </SubContainer>
    </Container>
  </Wrapper>
)

export default Footer;
