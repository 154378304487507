import styled, { css } from 'styled-components';

const angle = "4px";

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background.paper};
  box-shadow: 0 3px 6px 0px rgb(34 40 48 / 16%);

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-left: ${angle} solid ${({ theme }) => theme.background.paper};
    border-bottom: ${angle} solid transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${angle};
    bottom: 0;
    border-top: ${angle} solid transparent;
    border-right: ${angle} solid ${({ theme }) => theme.background.paper};
    border-bottom: ${angle} solid transparent;
  }

  width: 284px;

  ${props => props.size === "normal" && css`
    width: ${({ theme }) => theme.popup.normal}px;
  `}

  ${props => props.size === "medium" && css`
    width: ${({ theme }) => theme.popup.medium}px;
  `}

  ${props => props.size === "large" && css`
    width: ${({ theme }) => theme.popup.large}px;
  `}

  ${props => props.size === "video" && css`
    width: ${({ theme }) => theme.popup.video}px;
  `}

  ${props => props.abolsute && css`
    position: absolute;
    z-index: 1000;
  `}
`;

export const Header = styled.div`
  position: relative;
  padding: 16px 8px 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-family: "Quantico", sans-serif, sans-serif;
  color: ${({ theme }) => theme.text.title};
`

export const ButtonClose = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  top: 6px;
  right: 0px;
  background: transparent;

  svg{
    fill: ${({ theme }) => theme.primary.main};
  }

  &:hover{
    svg{
    fill: ${({ theme }) => theme.primary.light};
    }
  }
`;

export const Body = styled.div`
  padding: 16px;
  margin: -32px -4px 16px -4px;
`;

export const PopupModalFooter = styled.div`
  /* border: 1px solid red; */
`;
