import React from 'react';
import { Icon } from '../icons';
import {
  ButtonGroupFrame,
  ButtonIcon,
  ButtonIconLeft,
  ButtonIconRight,
  ButtonButton,
  ButtonLink,
  ButtonAnchorLink,
} from './button.styled'


const Blank = () => <>&nbsp;</>;
const getIconSize = (size) => {
  switch (size) {
    default:
    case "normal":
      return 14;
    case "small":
      return 18;
    case "icon":
      return 18;
    case "icon_small":
      return 15;
    case "icon_menu":
      return 21;
  }
}

export const ButtonGroup = (props) => {
  const { children, ...rest } = props;
  return (
    <ButtonGroupFrame {...rest}>
      {children}
    </ButtonGroupFrame>
  )
}

const ButtonContent = ({ size, icon, iconLeft, iconRight, loading, children }) => (
  <>
    {iconLeft
      ?
      <ButtonIconLeft>
        <Icon
          name={iconLeft}
          size={getIconSize(size)}
          noFill
        />
      </ButtonIconLeft>
      : null
    }
    {/* {loading
      ? 'loading..'
      : <> */}
    {icon
      ? <ButtonIcon>
        <Icon
          name={icon}
          size={getIconSize(size)}
          noFill
        />
      </ButtonIcon>
      : null
    }
    {children ? children : <Blank />}
    {/* </>
    } */}
    {iconRight
      ?
      <ButtonIconRight>
        <Icon
          name={iconRight}
          size={getIconSize(size)}
          noFill
        />
      </ButtonIconRight>
      : null
    }
  </>
);


export const Button = (props) => {
  const { id, type, to, target = "_blank", onClick, size, color, inline, align, fullwidth, disabled, nomargin, shadow, ...rest } = props;
  const styleProps = {
    size,
    color,
    align,
    nomargin: nomargin ? 1 : 0,
    fullwidth: fullwidth ? 1 : 0,
    inline: inline ? 1 : 0,
    shadow: shadow ? 1 : 0,
  }

  switch (type) {
    default:
    case "submit":
    case "button":
      return (
        <ButtonButton id={id} onClick={onClick} disabled={disabled} type={type} {...styleProps}>
          <ButtonContent size={size} {...rest} />
        </ButtonButton>
      )
    case "link":
      return (
        <ButtonLink id={id} to={to} onClick={onClick} {...styleProps}>
          <ButtonContent size={size}  {...rest} />
        </ButtonLink>
      )
    case "anchor":
      return (
        <ButtonAnchorLink id={id} href={to} target={target} {...styleProps}>
          <ButtonContent size={size} {...rest} />
        </ButtonAnchorLink>
      )
  }
}


export default Button;
