import styled, { css } from "styled-components";

const HEIGHT = '70px';
const HEIGHT_MOBILE = '60px';


export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: none;

  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  /* background: ${({ theme }) => theme.background.dark}aa; */

  @media ${({ theme }) => theme.device.phone}{
    display: block;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: ${HEIGHT};
  background: ${({ theme }) => theme.background.paper};
  border-top: 2px solid ${({ theme }) => theme.primary.main};
  z-index: 10000;

  @media only screen and (max-width: 520px){
  }

  @media ${({ theme }) => theme.device.phone}{
    height: ${HEIGHT_MOBILE};
  }

  &::before{
    content: ' ';
    position: absolute;
    bottom: -5px;
    height: 5px;
    right: 0;
    left: 0;
    box-shadow: 0px 2px 2px inset rgba(0, 0, 0, 0.08);
  }

  /* ${props => props.transparent && css`
    background: transparent;

    @media ${({ theme }) => theme.device.mobile}{
      background: ${({ theme }) => theme.background.paper};
    }

    &::before{
      box-shadow: none;
    }
  `} */

  @media ${({ theme }) => theme.device.phone}{
    transition: transform .2s ease-in-out;

    ${props => props.hidden && css`
      transform: translateY(-56px);
      transition: transform .2s ease-in-out;
    `}
  }
`;

export const Placeholder = styled.div`
  display: block;
  height: ${HEIGHT};

  ${props => props.hidden === true && css`
    display: none;
  `}

  @media ${({ theme }) => theme.device.phone}{
    height: ${HEIGHT_MOBILE};
  }
`;


export const Container = styled.div`
  max-width: ${({ theme }) => theme.size.max}px;
  height: ${HEIGHT};
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;
  display: block;
  z-index: 10000;

  @media ${({ theme }) => theme.device.mobile}{
    padding: 0 8px;
  }

  @media ${({ theme }) => theme.device.phone}{
    padding: 0 4px;
    height: ${HEIGHT_MOBILE};
  }
`;


export const Grid = styled.div`

  @media ${({ theme }) => theme.device.phone}{
    /* border: 1px solid red; */
  }

  ${props => props.container && css`
    display: grid;
    grid-template-areas: "logo space social basket menu";
    grid-template-columns: auto 1fr auto auto auto;
    height: 100%;

    @media ${({ theme }) => theme.device.tablet}{

    }

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: auto 1fr auto;
      grid-template-areas: "menu logo basket";
    }
    @media ${({ theme }) => theme.device.phone}{
      grid-template-columns: auto auto auto;
    }
  `}

  ${props => props.logo && css`
    grid-area: logo;
    align-self: center;

    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
    }

    @media ${({ theme }) => theme.device.phone}{
      /* display: none; */
    }
  `}

  ${props => props.social && css`
    grid-area: social;
    align-self: center;

    @media ${({ theme }) => theme.device.mobile}{
      display: none;
    }  
  `}

  ${props => props.space && css`
    grid-area: space;
    @media ${({ theme }) => theme.device.mobile}{
      display: none;
    }
  `}

  ${props => props.menu && css`
    grid-area: menu;
    align-self: center;

    @media ${({ theme }) => theme.device.mobile}{
      
    }
  `};

  ${props => props.basket && css`
    grid-area: basket;
    align-self: center;
  
    @media ${({ theme }) => theme.device.mobile}{
      /* justify-self: end; */
    }
  `}
`;

export const LogoButton = styled.a`
  cursor: pointer;

  ${props => props.hideInPhone && css`
    @media ${({ theme }) => theme.device.phone}{
      display: none;
    }
  `}
`

export const Logo = styled.img`
  width: auto;
  height: 38px;
  display: block;
  margin:  auto;

  @media only screen and (max-width: 520px){
    display: none;
  }
`;

export const LogoSimple = styled.img`
  width: auto;
  height: 42px;
  display: none;
  position: relative;
  margin:  auto;

  @media only screen and (max-width: 520px){
    display: block;
  }

  @media ${({ theme }) => theme.device.phone}{
    height: 32px;
  }

  @media only screen and (max-width: 380px){
    display: none;
  }
`;


export const ButtonLine = styled.div`
  
  ${props => props.container && css`
    display: flex;
    min-width: 280px;
    @media ${({ theme }) => theme.device.tabletPortrait}{
      min-width: auto;
    }
    @media ${({ theme }) => theme.device.phone}{
      min-width: auto;
    }
  `}

  ${props => props.item && css`
  flex: 1;

  
  @media ${({ theme }) => theme.device.tabletPortrait}{
    flex: auto;
  }
  @media ${({ theme }) => theme.device.phone}{
    flex: auto;
  }
    padding-right: 4px;
    &:last-child{
      padding-right: 0;
    }
  `}
`;
