import styled, { css } from "styled-components";
import {
  getAngleBySize,
  getAngleByCommonColor,
  getAngleByHierarchyColor,
  getAngleByStyleColor,
  COMMON_COLORS,
  HIEARARCHY_COLORS,
  STYLE_COLORS,
} from './z-components.helper';


export const ZWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background.paper};
  padding: 16px 8px;
  margin-left: 8px;
  margin-right: 8px;

  ${getAngleBySize({ angle: '8px' })}

  ${props => props.size === "large" && css`
    padding: 12px 0;
    margin-left: 12px;
    margin-right: 12px;
    ${getAngleBySize({ angle: '12px' })}
  `}

  ${props => props.size === "normal" && css`
    padding: 8px 0;
    margin-left: 8px;
    margin-right: 8px;
    ${getAngleBySize({ angle: '8px' })}
  `}

  ${props => props.size === "small" && css`
    padding: 4px 0;
    margin-left: 4px;
    margin-right: 4px;
    ${getAngleBySize({ angle: '4px' })}
  `}

  ${props => props.color
    && HIEARARCHY_COLORS.includes(props.color)
    && getAngleByHierarchyColor(props)
  };

  ${props => props.color
    && COMMON_COLORS.includes(props.color)
    && getAngleByCommonColor(props)
  };

  ${props => props.color
    && STYLE_COLORS.includes(props.color)
    && getAngleByStyleColor(props)
  };

  ${props => props.shadow && css`
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

    @media ${({ theme }) => theme.device.mobile}{
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  `}  
`;

export const ZTitle = styled.div`
  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: 16px;

  ${props => props.align === "center" && css`
    text-align: center;
  `}

  ${props => props.size === "large" && css`
    font-size: 21px;
    line-height: 25px;
  `}


`;


export const PageTitle = styled.h1`
  position: relative;
  text-align: center;
  font-family: "Quantico", sans-serif;
  font-size: 21px;
  /* padding: 16px 0 0; */
  /* letter-spacing: 1px; */
  /* display: inline-block; */
  display: block;
  margin: 16px auto 24px;
  color: ${({ theme }) => theme.text.primary};


  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 20%;
    right: 20%;
    bottom: -5px;
  }

  ${props => props.size === "large" && css`
      font-size: 32px;
      line-height:  36px;
      font-weight: 600;
      text-transform: uppercase;
  `}

  ${props => props.custom && css`
      font-size: 32px;
      line-height:  36px;
      font-weight: 400;
  `}
`;


export const PageTitleSimple = styled.div`
  position: relative;
  text-align: center;
  font-family: "Quantico", sans-serif;
  font-size: 24px;
  line-height: 28px;
  padding: 0 16px;
  display: block;
  margin: 16px auto 24px;
  color: ${({ theme }) => theme.text.primary};


  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 20%;
    right: 20%;
    bottom: -5px;
  }
`;


export const PageHTML = styled.div`
  text-align: left;
  margin-top: -4px;
  padding-left: 10px;
  padding-right: 10px;

  @media ${({theme}) => theme.device.mobile}{
  }

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.right && css`
    text-align: right;
  `}

  ${props => props.left && css`
    text-align: left;
  `}

  ol, ul, p {
    margin: 16px 0;
    font-size: 16px;
    line-height: 24px;


    small{
      font-size: 14px;
      line-height: 21px;
    }
  }

  ol{
    list-style-type: lower-alpha;
    ol{
    list-style-type: lower-roman;

    }
  }

  li {
    margin: 16px 0;
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 32px;    
    font-size: 24px;
    font-family: "Quantico", sans-serif;
    letter-spacing: 1.5px;
    color: ${({ theme }) => theme.text.secondary};
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
    font-family: "Quantico", sans-serif;
    font-weight: normal;
    color: ${({ theme }) => theme.text.secondary};
  }


  ${props => props.terms && css`
  `}
`;
