const IconCollection = {
  default: {
    viewBox: '0 0 32 33',
    d: ['M 2.56635 12.9241C 0.708307 9.55549 0 6.83983 0 5.00558C 0 3.17134 0.450658 2.64526 0.907953 2.22025C 1.36525 1.79524 3.42732 0.523908 3.77867 0.286808C 4.13002 0.0497085 5.47099 -0.41107 6.31193 0.798636C 7.15287 2.00834 8.73646 4.43718 9.82825 6.05069C 11.5415 8.33611 10.1766 9.33937 9.73572 9.94069C 8.92447 11.0472 8.45734 11.3201 8.45734 12.6788C 8.45734 14.0375 12.2545 17.8976 13.1625 18.8487C 14.0635 19.7926 17.8471 23.1094 19.0195 23.2868C 20.2002 23.4654 21.7807 22.2154 22.1168 21.8985C 23.8263 20.586 24.7912 21.581 25.5787 22.0136C 26.3661 22.4461 29.9239 24.663 31.0264 25.4103C 32.0641 26.1576 31.9992 27.3125 31.9992 27.3125C 31.9992 27.3125 29.859 30.7092 29.5996 31.1168C 29.2753 31.5924 28.4971 32 26.746 32C 24.995 32 23.1241 31.6802 18.6777 29.2349C 15.0396 27.234 11.5714 24.1009 9.75551 22.2666C 7.87475 20.4324 4.68876 16.772 2.56635 12.9241Z'],
  },
  'arrow-ios-downward-outline': {
    viewBox: '0 0 24 24',
    d: ['M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z'],
  },
  'arrow-ios-upward-outline': {
    viewBox: '0 0 24 24',
    d: ['M18 15a1 1 0 0 1-.64-.23L12 10.29l-5.37 4.32a1 1 0 0 1-1.41-.15 1 1 0 0 1 .15-1.41l6-4.83a1 1 0 0 1 1.27 0l6 5a1 1 0 0 1 .13 1.41A1 1 0 0 1 18 15z'],
  },
  'pin-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M12 2a8 8 0 0 0-8 7.92c0 5.48 7.05 11.58 7.35 11.84a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 9.92A8 8 0 0 0 12 2zm0 17.65c-1.67-1.59-6-6-6-9.73a6 6 0 0 1 12 0c0 3.7-4.33 8.14-6 9.73z',
      'M12 6a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12 6zm0 5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 11z',
    ],
  },
  'phone-outline': {
    viewBox: '0 0 24 24',
    d: ['M17.4 22A15.42 15.42 0 0 1 2 6.6 4.6 4.6 0 0 1 6.6 2a3.94 3.94 0 0 1 .77.07 3.79 3.79 0 0 1 .72.18 1 1 0 0 1 .65.75l1.37 6a1 1 0 0 1-.26.92c-.13.14-.14.15-1.37.79a9.91 9.91 0 0 0 4.87 4.89c.65-1.24.66-1.25.8-1.38a1 1 0 0 1 .92-.26l6 1.37a1 1 0 0 1 .72.65 4.34 4.34 0 0 1 .19.73 4.77 4.77 0 0 1 .06.76A4.6 4.6 0 0 1 17.4 22zM6.6 4A2.61 2.61 0 0 0 4 6.6 13.41 13.41 0 0 0 17.4 20a2.61 2.61 0 0 0 2.6-2.6v-.33L15.36 16l-.29.55c-.45.87-.78 1.5-1.62 1.16a11.85 11.85 0 0 1-7.18-7.21c-.36-.78.32-1.14 1.18-1.59L8 8.64 6.93 4z'],
  },
  'arrow-back-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z'],
  },
  'search-outline': {
    viewBox: '0 0 24 24',
    d: ['M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z'],
  },
  star: {
    viewBox: '0 0 24 24',
    d: ['M17.56 21a1 1 0 0 1-.46-.11L12 18.22l-5.1 2.67a1 1 0 0 1-1.45-1.06l1-5.63-4.12-4a1 1 0 0 1-.25-1 1 1 0 0 1 .81-.68l5.7-.83 2.51-5.13a1 1 0 0 1 1.8 0l2.54 5.12 5.7.83a1 1 0 0 1 .81.68 1 1 0 0 1-.25 1l-4.12 4 1 5.63a1 1 0 0 1-.4 1 1 1 0 0 1-.62.18z'],
  },
  'home-outline': {
    viewBox: '0 0 24 24',
    d: ['M20.42 10.18L12.71 2.3a1 1 0 0 0-1.42 0l-7.71 7.89A2 2 0 0 0 3 11.62V20a2 2 0 0 0 1.89 2h14.22A2 2 0 0 0 21 20v-8.38a2.07 2.07 0 0 0-.58-1.44zM10 20v-6h4v6zm9 0h-3v-7a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v7H5v-8.42l7-7.15 7 7.19z'],
  },
  person: {
    viewBox: '0 0 24 24',
    d: [
      'M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4z',
      'M18 21a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1z',
    ],
  },
  'person-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z',
      'M12 13a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z',
    ],
  },
  'arrow-left': {
    viewBox: '0 0 24 24',
    d: ['M13.54 18a2.06 2.06 0 0 1-1.3-.46l-5.1-4.21a1.7 1.7 0 0 1 0-2.66l5.1-4.21a2.1 2.1 0 0 1 2.21-.26 1.76 1.76 0 0 1 1.05 1.59v8.42a1.76 1.76 0 0 1-1.05 1.59 2.23 2.23 0 0 1-.91.2z'],
  },
  'arrow-right': {
    viewBox: '0 0 24 24',
    d: ['M10.46 18a2.23 2.23 0 0 1-.91-.2 1.76 1.76 0 0 1-1.05-1.59V7.79A1.76 1.76 0 0 1 9.55 6.2a2.1 2.1 0 0 1 2.21.26l5.1 4.21a1.7 1.7 0 0 1 0 2.66l-5.1 4.21a2.06 2.06 0 0 1-1.3.46z'],
  },
  'close-outline': {
    viewBox: '0 0 24 24',
    d: ['M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z'],
  },
  'arrow-ios-back-outline': {
    viewBox: '0 0 24 24',
    d: ['M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z'],
  },
  'arrow-ios-forward-outline': {
    viewBox: '0 0 24 24',
    d: ['M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z'],
  },
  'briefcase': {
    viewBox: '0 0 24 24',
    d: ['M7 21h10V7h-1V5.5A2.5 2.5 0 0 0 13.5 3h-3A2.5 2.5 0 0 0 8 5.5V7H7zm3-15.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V7h-4z',
      'M19 7v14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3z',
      'M5 7a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3z'],
  },
  'briefcase-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 7h-3V5.5A2.5 2.5 0 0 0 13.5 3h-3A2.5 2.5 0 0 0 8 5.5V7H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3zm-4 2v10H9V9zm-5-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V7h-4zM4 18v-8a1 1 0 0 1 1-1h2v10H5a1 1 0 0 1-1-1zm16 0a1 1 0 0 1-1 1h-2V9h2a1 1 0 0 1 1 1z'],
  },
  'checkmark-circle-outline': {
    viewBox: '0 0 24 24',
    d: ['M9.71 11.29a1 1 0 0 0-1.42 1.42l3 3A1 1 0 0 0 12 16a1 1 0 0 0 .72-.34l7-8a1 1 0 0 0-1.5-1.32L12 13.54z',
      'M21 11a1 1 0 0 0-1 1 8 8 0 0 1-8 8A8 8 0 0 1 6.33 6.36 7.93 7.93 0 0 1 12 4a8.79 8.79 0 0 1 1.9.22 1 1 0 1 0 .47-1.94A10.54 10.54 0 0 0 12 2a10 10 0 0 0-7 17.09A9.93 9.93 0 0 0 12 22a10 10 0 0 0 10-10 1 1 0 0 0-1-1z'],
  },
  'user-solid': {
    viewBox: '0 0 448 512',
    d: ['M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'],
  },
  'upload-outline': {
    viewBox: '0 0 24 24',
    rect: [
      {
        width: '24',
        height: '24',
        transform: 'rotate(180 12 12)',
        opacity: '0',
      },
      {
        x: '4',
        y: '4',
        width: '16',
        height: '2',
        rx: '1',
        ry: '1',
        transform: 'rotate(180 12 5)',
      },
      {
        x: '17',
        y: '5',
        width: '4',
        height: '2',
        rx: '1',
        ry: '1',
        transform: 'rotate(90 19 6)',
      },
      {
        x: '3',
        y: '5',
        width: '4',
        height: '2',
        rx: '1',
        ry: '1',
        transform: 'rotate(90 5 6)',
      },
    ],
    d: ['M8 14a1 1 0 0 1-.8-.4 1 1 0 0 1 .2-1.4l4-3a1 1 0 0 1 1.18 0l4 2.82a1 1 0 0 1 .24 1.39 1 1 0 0 1-1.4.24L12 11.24 8.6 13.8a1 1 0 0 1-.6.2z',
      'M12 21a1 1 0 0 1-1-1v-8a1 1 0 0 1 2 0v8a1 1 0 0 1-1 1z'],
  },
  'plus-circle': {
    viewBox: '0 0 24 24',
    d: ['M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm3 11h-2v2a1 1 0 0 1-2 0v-2H9a1 1 0 0 1 0-2h2V9a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2z'],
  },
  'checkmark-square-active': {
    viewBox: '0 0 24 24',
    d: ['M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm-1.7 6.61l-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z'],
  },
  'checkmark-square': {
    viewBox: '0 0 24 24',
    d: ['M18 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3zM6 5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z'],
  },
  'checkmark-circle-active': {
    viewBox: '0 0 24 24',
    d: ['M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'],
    circle: [
      { cx: '12', cy: '12', r: '4' },
    ],
  },
  'checkmark-circle': {
    viewBox: '0 0 24 24',
    d: ['M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'],
  },
  'camera-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 7h-3V5.5A2.5 2.5 0 0 0 13.5 3h-3A2.5 2.5 0 0 0 8 5.5V7H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3zm-9-1.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V7h-4zM20 18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z',
      'M12 10.5a3.5 3.5 0 1 0 3.5 3.5 3.5 3.5 0 0 0-3.5-3.5zm0 5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z'],
  },
  'more-horizontal-outline': {
    viewBox: '0 0 24 24',
    circle: [
      { cx: '12', cy: '12', r: '2' },
      { cx: '19', cy: '12', r: '2' },
      { cx: '5', cy: '12', r: '2' },
    ],
  },
  checkmark: {
    viewBox: '0 0 24 24',
    d: ['M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z'],
  },
  'share-outline': {
    viewBox: '0 0 24 24',
    d: ['M18 15a3 3 0 0 0-2.1.86L8 12.34V12v-.33l7.9-3.53A3 3 0 1 0 15 6v.34L7.1 9.86a3 3 0 1 0 0 4.28l7.9 3.53V18a3 3 0 1 0 3-3zm0-10a1 1 0 1 1-1 1 1 1 0 0 1 1-1zM5 13a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm13 6a1 1 0 1 1 1-1 1 1 0 0 1-1 1z'],
  },
  'star-outline': {
    viewBox: '0 0 24 24',
    d: ['M17.56 21a1 1 0 0 1-.46-.11L12 18.22l-5.1 2.67a1 1 0 0 1-1.45-1.06l1-5.63-4.12-4a1 1 0 0 1-.25-1 1 1 0 0 1 .81-.68l5.7-.83 2.51-5.13a1 1 0 0 1 1.8 0l2.54 5.12 5.7.83a1 1 0 0 1 .81.68 1 1 0 0 1-.25 1l-4.12 4 1 5.63a1 1 0 0 1-.4 1 1 1 0 0 1-.62.18zM12 16.1a.92.92 0 0 1 .46.11l3.77 2-.72-4.21a1 1 0 0 1 .29-.89l3-2.93-4.2-.62a1 1 0 0 1-.71-.56L12 5.25 10.11 9a1 1 0 0 1-.75.54l-4.2.62 3 2.93a1 1 0 0 1 .29.89l-.72 4.16 3.77-2a.92.92 0 0 1 .5-.04z'],
  },
  'edit-outline': {
    viewBox: '0 0 24 24',
    d: ['M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7zM16 10.68L13.32 8l1.95-2L18 8.73z'],
  },
  'trash-2-outline': {
    viewBox: '0 0 24 24',
    d: ['M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z',
      'M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z',
      'M15 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z'],
  },
  'trash': {
    viewBox: '0 0 24 24',
    d: ['M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4z'],
  },
  'navigation-2-outline': {
    viewBox: '0 0 24 24',
    d: ['M13.67 22h-.06a1 1 0 0 1-.92-.8L11 13l-8.2-1.69a1 1 0 0 1-.12-1.93l16-5.33A1 1 0 0 1 20 5.32l-5.33 16a1 1 0 0 1-1 .68zm-6.8-11.9l5.19 1.06a1 1 0 0 1 .79.78l1.05 5.19 3.52-10.55z'],
  },
  'arrow-forward': {
    viewBox: '0 0 24 24',
    d: ['M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z'],
  },
  'settings-2-outline': {
    viewBox: '0 0 24 24',
    d: ['M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5 1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19 1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5 1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14 2.36 2.36 0 0 1 2.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 0 1-.48-2.56 2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46 2.36 2.36 0 0 1 .47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14 2.36 2.36 0 0 1-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46 2.36 2.36 0 0 1-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 0 1 4 1.67z',
      'M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z'],
  },
  'log-in': {
    viewBox: '0 0 24 24',
    d: ['M19 4h-2a1 1 0 0 0 0 2h1v12h-1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z',
      'M11.8 7.4a1 1 0 0 0-1.6 1.2L12 11H4a1 1 0 0 0 0 2h8.09l-1.72 2.44a1 1 0 0 0 .24 1.4 1 1 0 0 0 .58.18 1 1 0 0 0 .81-.42l2.82-4a1 1 0 0 0 0-1.18z'],
  },
  activity: {
    viewBox: '0 0 24 24',
    d: ['M14.33 20h-.21a2 2 0 0 1-1.76-1.58L9.68 6l-2.76 6.4A1 1 0 0 1 6 13H3a1 1 0 0 1 0-2h2.34l2.51-5.79a2 2 0 0 1 3.79.38L14.32 18l2.76-6.38A1 1 0 0 1 18 11h3a1 1 0 0 1 0 2h-2.34l-2.51 5.79A2 2 0 0 1 14.33 20z'],
  },
  menu: {
    viewBox: '0 0 24 24',
    circle: [
      { cx: '4', cy: '12', r: '1' },
    ],
    rect: [
      {
        x: '7',
        y: '11',
        width: '14',
        height: '2',
        rx: '.94',
        ry: '.94',
      },
      {
        x: '3',
        y: '16',
        width: '18',
        height: '2',
        rx: '.94',
        ry: '.94',
      },
      {
        x: '3',
        y: '6',
        width: '18',
        height: '2',
        rx: '.94',
        ry: '.94',
      },
    ],
  },
  'loader-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M12 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1z',
      'M21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z',
      'M6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1z',
      'M6.22 5a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0 0-1.41z',
      'M17 8.14a1 1 0 0 0 .69-.28l1.44-1.39A1 1 0 0 0 17.78 5l-1.44 1.42a1 1 0 0 0 0 1.41 1 1 0 0 0 .66.31z',
      'M12 18a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1z',
      'M17.73 16.14a1 1 0 0 0-1.39 1.44L17.78 19a1 1 0 0 0 .69.28 1 1 0 0 0 .72-.3 1 1 0 0 0 0-1.42z',
      'M6.27 16.14l-1.44 1.39a1 1 0 0 0 0 1.42 1 1 0 0 0 .72.3 1 1 0 0 0 .67-.25l1.44-1.39a1 1 0 0 0-1.39-1.44z',
    ],
  },
  'alert-triangle-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M22.56 16.3L14.89 3.58a3.43 3.43 0 0 0-5.78 0L1.44 16.3a3 3 0 0 0-.05 3A3.37 3.37 0 0 0 4.33 21h15.34a3.37 3.37 0 0 0 2.94-1.66 3 3 0 0 0-.05-3.04zm-1.7 2.05a1.31 1.31 0 0 1-1.19.65H4.33a1.31 1.31 0 0 1-1.19-.65 1 1 0 0 1 0-1l7.68-12.73a1.48 1.48 0 0 1 2.36 0l7.67 12.72a1 1 0 0 1 .01 1.01z',
      'M12 8a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V9a1 1 0 0 0-1-1z',
    ],
    circle: [
      { cx: '12', cy: '16', r: '1' },
    ],
  },
  'message-circle-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M19.07 4.93a10 10 0 0 0-16.28 11 1.06 1.06 0 0 1 .09.64L2 20.8a1 1 0 0 0 .27.91A1 1 0 0 0 3 22h.2l4.28-.86a1.26 1.26 0 0 1 .64.09 10 10 0 0 0 11-16.28zm.83 8.36a8 8 0 0 1-11 6.08 3.26 3.26 0 0 0-1.25-.26 3.43 3.43 0 0 0-.56.05l-2.82.57.57-2.82a3.09 3.09 0 0 0-.21-1.81 8 8 0 0 1 6.08-11 8 8 0 0 1 9.19 9.19z',
    ],
    circle: [
      { cx: '12', cy: '12', r: '1' },
      { cx: '16', cy: '12', r: '1' },
      { cx: '8', cy: '12', r: '1' },
    ],
  },
  'bell-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M20.52 15.21l-1.8-1.81V8.94a6.86 6.86 0 0 0-5.82-6.88 6.74 6.74 0 0 0-7.62 6.67v4.67l-1.8 1.81A1.64 1.64 0 0 0 4.64 18H8v.34A3.84 3.84 0 0 0 12 22a3.84 3.84 0 0 0 4-3.66V18h3.36a1.64 1.64 0 0 0 1.16-2.79zM14 18.34A1.88 1.88 0 0 1 12 20a1.88 1.88 0 0 1-2-1.66V18h4zM5.51 16l1.18-1.18a2 2 0 0 0 .59-1.42V8.73A4.73 4.73 0 0 1 8.9 5.17 4.67 4.67 0 0 1 12.64 4a4.86 4.86 0 0 1 4.08 4.9v4.5a2 2 0 0 0 .58 1.42L18.49 16z',
    ],
  },
  'shopping-cart-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7zm-4.7 7H8.76L7.13 8h12.25z',
    ],
    circle: [
      { cx: '7.5', cy: '19.5', r: '1.5' },
      { cx: '17.5', cy: '19.5', r: '1.5' },
    ],
  },
  'shopping-cart': {
    viewBox: '0 0 24 24',
    d: [
      'M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7z',
    ],
    circle: [
      { cx: '7.5', cy: '19.5', r: '1.5' },
      { cx: '17.5', cy: '19.5', r: '1.5' },
    ],
  },
  'shopping-cart-2': {
    viewBox: '0 0 1024 1024',
    d: [
      'M298.666667 768c-46.933333 0-85.333333 38.4-85.333333 85.333333s38.4 85.333333 85.333333 85.333333c46.933333 0 85.333333-38.4 85.333333-85.333333S345.6 768 298.666667 768zM42.666667 85.333333l0 85.333333 85.333333 0 153.6 324.266667L221.866667 597.333333C217.6 610.133333 213.333333 627.2 213.333333 640c0 46.933333 38.4 85.333333 85.333333 85.333333l512 0 0-85.333333L315.733333 640c-4.266667 0-8.533333-4.266667-8.533333-8.533333 0 0 0-4.266667 0-4.266667L345.6 554.666667l315.733333 0c34.133333 0 59.733333-17.066667 72.533333-42.666667l153.6-277.333333C896 226.133333 896 221.866667 896 213.333333c0-25.6-17.066667-42.666667-42.666667-42.666667L221.866667 170.666667 183.466667 85.333333 42.666667 85.333333zM725.333333 768c-46.933333 0-85.333333 38.4-85.333333 85.333333s38.4 85.333333 85.333333 85.333333c46.933333 0 85.333333-38.4 85.333333-85.333333S772.266667 768 725.333333 768z',
    ],
  },
  'credit-card-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M19 5H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3zM4 8a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1H4zm16 8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5h16z',
      'M7 15h4a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2z',
      'M15 15h2a1 1 0 0 0 0-2h-2a1 1 0 0 0 0 2z',
    ],
  },
  'cash-icon': {
    viewBox: '0 0 24 24',
    d: [
      'M14.0561758,14.7162071 C13.7942082,14.4943233 11.0601337,11.2073226 10.3218384,10.7429119 L10.3218384,2.213226 C10.3218384,1.34632508 9.6740394,0.649708313 8.87858699,0.649708313 L1.44325139,0.649708313 C0.643038236,0.649708313 0,1.35148523 0,2.213226 L0,17.1466241 C0,18.0135236 0.647798978,18.7101427 1.44325139,18.7101427 L3.82960862,18.7101427 L4.68221811,18.7101427 L4.54884691,17.4820333 C4.50598614,17.4407525 4.4774076,17.4149518 4.43453275,17.3736692 L3.95821909,17.3736692 L3.00557767,17.3736692 C3.05321327,17.1672635 3.06749549,16.9402204 3.02939547,16.7080141 C2.9246028,16.0629983 2.45304988,15.5418249 1.85764724,15.423143 C1.63854041,15.3767028 1.42419434,15.3921819 1.22890531,15.4437833 L1.22890531,3.81802444 C1.31464093,3.83350496 1.40513728,3.84382538 1.49564772,3.84382538 C2.30538236,3.84382538 2.95794208,3.13172821 2.95794208,2.25966708 C2.95794208,2.16678504 2.9484206,2.07390265 2.93412429,1.98102049 L7.37341778,1.98102049 C7.35913555,2.07390265 7.34961407,2.16678504 7.34961407,2.25966708 C7.34961407,3.13688831 8.00215971,3.84382538 8.81190843,3.84382538 C8.90240479,3.84382538 8.99290114,3.83350496 9.07865084,3.81802444 L9.07865084,15.1651369 L9.07865084,18.4263368 C8.74204665,18.158011 8.3863901,17.6041576 8.01168119,16.7647767 C7.82751465,14.5321629 7.21306904,13.4158559 6.16834435,13.4158559 C6.1254695,13.4158559 6.08736948,13.4158559 6.04449463,13.4210163 C5.25379826,13.4932577 5.1855234,14.7540479 5.83967003,17.2033868 L6.27788368,21.2850442 C6.60495699,22.76084 7.219412,24.0095907 8.12124869,25.0312963 L8.12124869,25.4853845 C8.12124869,25.7279115 8.3022414,25.9291559 8.5308697,25.9291559 L12.936838,25.9291559 C13.1464093,25.9291559 13.3178805,25.7588735 13.3416983,25.5369878 C13.4988873,23.9476686 14.1085862,18.0393262 14.4658109,17.1414648 C14.5229821,16.9969812 14.5706036,16.8524957 14.5896606,16.6976915 C14.6706355,16.1920038 14.6992141,15.2631813 14.0561758,14.7162071 Z',
      'M1.99566853,9.8863468 C1.99566853,11.5221119 3.05310261,12.8895435 4.46777547,13.2352695 C4.51063623,13.1475468 4.55351108,13.0701465 4.60589333,12.9875889 C5.07269959,12.2496891 5.70620226,12.1155262 5.92054834,12.0948887 C6.00626987,12.0845661 6.09201957,12.0794086 6.1777411,12.0794086 C6.48259762,12.0794086 6.89699346,12.1413288 7.32568562,12.3890095 C7.94013123,11.7646351 8.32596263,10.8719349 8.32596263,9.8863468 C8.32596263,7.99258612 6.90652903,6.4548664 5.15843521,6.4548664 C3.41034138,6.4548664 1.99566853,7.99258612 1.99566853,9.8863468 Z',
    ],
    circle: [
      {
        cx: '5.32010382', cy: '5.02863269', r: '0.5',
      },
    ],
  },
  'bank-icon': {
    viewBox: '0 0 24 24',
    d: [
      'M1.61280006,6.72001277 L22.5792009,6.72001277 C22.9594636,6.7221488 23.2894902,6.44728448 23.3711583,6.06041455 C23.4528263,5.67354463 23.2639387,5.27981907 22.9178889,5.1156002 L12.4346885,0.075600003 C12.2198428,-0.0279730042 11.9721582,-0.0279730042 11.7573125,0.075600003 L1.27411205,5.1156002 C0.928062269,5.27981907 0.739174649,5.67354463 0.820842688,6.06041455 C0.902510726,6.44728448 1.23253732,6.7221488 1.61280006,6.72001277 Z M12.0960005,1.76400007 L18.9100808,5.0400002 L5.28192021,5.0400002 L12.0960005,1.76400007 Z',
      'M1.61280006,23.5200009 L22.5792009,23.5200009 C23.0245633,23.5200009 23.3856009,23.1439201 23.3856009,22.6800009 C23.3856009,22.2160817 23.0245633,21.8400009 22.5792009,21.8400009 L1.61280006,21.8400009 C1.16743762,21.8400009 0.806400032,22.2160817 0.806400032,22.6800009 C0.806400032,23.1439201 1.16743762,23.5200009 1.61280006,23.5200009 Z',
      'M2.4192001,20.1600008 C2.4192001,20.62392 2.78023769,21.0000008 3.22560013,21.0000008 L20.9664008,21.0000008 C21.4117633,21.0000008 21.7728009,20.62392 21.7728009,20.1600008 C21.7728009,19.6960816 21.4117633,19.3200008 20.9664008,19.3200008 L3.22560013,19.3200008 C2.78023769,19.3200008 2.4192001,19.6960816 2.4192001,20.1600008 Z',
      'M20.9664008,7.5600003 L3.22560013,7.5600003 C2.78023769,7.5600003 2.4192001,7.93608113 2.4192001,8.40000033 C2.4192001,8.86391954 2.78023769,9.24000037 3.22560013,9.24000037 L20.9664008,9.24000037 C21.4117633,9.24000037 21.7728009,8.86391954 21.7728009,8.40000033 C21.7728009,7.93608113 21.4117633,7.5600003 20.9664008,7.5600003 Z',
      'M5.64480022,17.6400007 L5.64480022,10.9200004 C5.64480022,10.4560812 5.28376263,10.0800004 4.83840019,10.0800004 C4.39303775,10.0800004 4.03200016,10.4560812 4.03200016,10.9200004 L4.03200016,17.6400007 C4.03200016,18.1039199 4.39303775,18.4800007 4.83840019,18.4800007 C5.28376263,18.4800007 5.64480022,18.1039199 5.64480022,17.6400007 Z',
      'M10.4832004,17.6400007 L10.4832004,10.9200004 C10.4832004,10.4560812 10.1221628,10.0800004 9.67680038,10.0800004 C9.23143794,10.0800004 8.87040035,10.4560812 8.87040035,10.9200004 L8.87040035,17.6400007 C8.87040035,18.1039199 9.23143794,18.4800007 9.67680038,18.4800007 C10.1221628,18.4800007 10.4832004,18.1039199 10.4832004,17.6400007 Z',
      'M15.3216006,17.6400007 L15.3216006,10.9200004 C15.3216006,10.4560812 14.960563,10.0800004 14.5152006,10.0800004 C14.0698381,10.0800004 13.7088005,10.4560812 13.7088005,10.9200004 L13.7088005,17.6400007 C13.7088005,18.1039199 14.0698381,18.4800007 14.5152006,18.4800007 C14.960563,18.4800007 15.3216006,18.1039199 15.3216006,17.6400007 Z',
      'M20.1600008,17.6400007 L20.1600008,10.9200004 C20.1600008,10.4560812 19.7989632,10.0800004 19.3536008,10.0800004 C18.9082383,10.0800004 18.5472007,10.4560812 18.5472007,10.9200004 L18.5472007,17.6400007 C18.5472007,18.1039199 18.9082383,18.4800007 19.3536008,18.4800007 C19.7989632,18.4800007 20.1600008,18.1039199 20.1600008,17.6400007 Z',
    ],
  },
  'copy-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M18 21h-6a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3zm-6-10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z',
      'M9.73 15H5.67A2.68 2.68 0 0 1 3 12.33V5.67A2.68 2.68 0 0 1 5.67 3h6.66A2.68 2.68 0 0 1 15 5.67V9.4h-2V5.67a.67.67 0 0 0-.67-.67H5.67a.67.67 0 0 0-.67.67v6.66a.67.67 0 0 0 .67.67h4.06z',
    ],
  },
  'info-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z',
      'M12 10a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z',
    ],
    circle: [
      { cx: '12', cy: '8', r: '1' },
    ],
  },
  'clock-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z',
      'M16 11h-3V8a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z',
    ],
  },
  'award-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 20.75l-2.31-9A5.94 5.94 0 0 0 18 8 6 6 0 0 0 6 8a5.94 5.94 0 0 0 1.34 3.77L5 20.75a1 1 0 0 0 1.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0 0 18 22a1 1 0 0 0 1-1.25zM12 4a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm.31 12.71a1 1 0 0 0-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 0 0 5.92 0L16.45 19z'],
  },
  'plus-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z'],
  },
  'minus-outline': {
    viewBox: '0 0 24 24',
    d: ['M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z'],
  },
  'expand-arrows': {
    viewBox: '0 0 1024 1024',
    d: ['M896 341.333333a42.666667 42.666667 0 0 0 42.666667-42.666666V128a42.666667 42.666667 0 0 0-3.413334-16.213333 42.666667 42.666667 0 0 0-23.04-23.04A42.666667 42.666667 0 0 0 896 85.333333h-170.666667a42.666667 42.666667 0 0 0 0 85.333334h67.84L512 451.84 230.826667 170.666667H298.666667a42.666667 42.666667 0 0 0 0-85.333334H128a42.666667 42.666667 0 0 0-16.213333 3.413334 42.666667 42.666667 0 0 0-23.04 23.04A42.666667 42.666667 0 0 0 85.333333 128v170.666667a42.666667 42.666667 0 0 0 85.333334 0V230.826667L451.84 512 170.666667 793.173333V725.333333a42.666667 42.666667 0 0 0-85.333334 0v170.666667a42.666667 42.666667 0 0 0 3.413334 16.213333 42.666667 42.666667 0 0 0 23.04 23.04A42.666667 42.666667 0 0 0 128 938.666667h170.666667a42.666667 42.666667 0 0 0 0-85.333334H230.826667L512 572.16 793.173333 853.333333H725.333333a42.666667 42.666667 0 0 0 0 85.333334h170.666667a42.666667 42.666667 0 0 0 16.213333-3.413334 42.666667 42.666667 0 0 0 23.04-23.04A42.666667 42.666667 0 0 0 938.666667 896v-170.666667a42.666667 42.666667 0 0 0-85.333334 0v67.84L572.16 512 853.333333 230.826667V298.666667a42.666667 42.666667 0 0 0 42.666667 42.666666z'],
  },
  'expand': {
    viewBox: '0 0 1024 1024',
    d: ['M793.59 793.6H640V896h256V640h-102.41z m0.01-563.19V384H896V128H640v102.41z m-563.19-0.01H384V128H128v256h102.41z m-0.01 563.19V640H128v256h256v-102.41z'],
  },
  'swap-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M4 9h13l-1.6 1.2a1 1 0 0 0-.2 1.4 1 1 0 0 0 .8.4 1 1 0 0 0 .6-.2l4-3a1 1 0 0 0 0-1.59l-3.86-3a1 1 0 0 0-1.23 1.58L17.08 7H4a1 1 0 0 0 0 2z',
      'M20 16H7l1.6-1.2a1 1 0 0 0-1.2-1.6l-4 3a1 1 0 0 0 0 1.59l3.86 3a1 1 0 0 0 .61.21 1 1 0 0 0 .79-.39 1 1 0 0 0-.17-1.4L6.92 18H20a1 1 0 0 0 0-2z',
    ],
  },
  'calendar-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1zm12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1z',
      'M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z',
    ],
    circle: [
      { cx: '8', cy: '16', r: '1' },
    ],
  },
  'corner-up-right': {
    viewBox: '0 0 24 24',
    d: [
      'M19.78 10.38l-4-5a1 1 0 0 0-1.56 1.24l2.7 3.38H8a3 3 0 0 0-3 3v5a1 1 0 0 0 2 0v-5a1 1 0 0 1 1-1h8.92l-2.7 3.38a1 1 0 0 0 .16 1.4A1 1 0 0 0 15 17a1 1 0 0 0 .78-.38l4-5a1 1 0 0 0 0-1.24z',
    ],
  },
  'corner-up-left': {
    viewBox: '0 0 24 24',
    d: [
      'M16 10H7.08l2.7-3.38a1 1 0 1 0-1.56-1.24l-4 5a1 1 0 0 0 0 1.24l4 5A1 1 0 0 0 9 17a1 1 0 0 0 .62-.22 1 1 0 0 0 .16-1.4L7.08 12H16a1 1 0 0 1 1 1v5a1 1 0 0 0 2 0v-5a3 3 0 0 0-3-3z',
    ],
  },
  'refresh-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z',
    ],
  },
  'printer-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M19.36 7H18V5a1.92 1.92 0 0 0-1.83-2H7.83A1.92 1.92 0 0 0 6 5v2H4.64A2.66 2.66 0 0 0 2 9.67v6.66A2.66 2.66 0 0 0 4.64 19h.86a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2h.86A2.66 2.66 0 0 0 22 16.33V9.67A2.66 2.66 0 0 0 19.36 7zM8 5h8v2H8zm-.5 14v-4h9v4zM20 16.33a.66.66 0 0 1-.64.67h-.86v-2a2 2 0 0 0-2-2h-9a2 2 0 0 0-2 2v2h-.86a.66.66 0 0 1-.64-.67V9.67A.66.66 0 0 1 4.64 9h14.72a.66.66 0 0 1 .64.67z',
    ],
  },
  'sync-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M21.66 10.37a.62.62 0 0 0 .07-.19l.75-4a1 1 0 0 0-2-.36l-.37 2a9.22 9.22 0 0 0-16.58.84 1 1 0 0 0 .55 1.3 1 1 0 0 0 1.31-.55A7.08 7.08 0 0 1 12.07 5a7.17 7.17 0 0 1 6.24 3.58l-1.65-.27a1 1 0 1 0-.32 2l4.25.71h.16a.93.93 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.08-.1a1.07 1.07 0 0 0 .14-.16.58.58 0 0 0 .05-.16z',
      'M19.88 14.07a1 1 0 0 0-1.31.56A7.08 7.08 0 0 1 11.93 19a7.17 7.17 0 0 1-6.24-3.58l1.65.27h.16a1 1 0 0 0 .16-2L3.41 13a.91.91 0 0 0-.33 0H3a1.15 1.15 0 0 0-.32.14 1 1 0 0 0-.18.18l-.09.1a.84.84 0 0 0-.07.19.44.44 0 0 0-.07.17l-.75 4a1 1 0 0 0 .8 1.22h.18a1 1 0 0 0 1-.82l.37-2a9.22 9.22 0 0 0 16.58-.83 1 1 0 0 0-.57-1.28z',
    ],
  },
  'email-outline': {
    viewBox: '0 0 24 24',
    d: [
      'M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z',
    ],
  },
  'arrowhead-left': {
    viewBox: '0 0 24 24',
    d: [
      'M11.64 5.23a1 1 0 0 0-1.41.13l-5 6a1 1 0 0 0 0 1.27l4.83 6a1 1 0 0 0 .78.37 1 1 0 0 0 .78-1.63L7.29 12l4.48-5.37a1 1 0 0 0-.13-1.4z',
      'M14.29 12l4.48-5.37a1 1 0 0 0-1.54-1.28l-5 6a1 1 0 0 0 0 1.27l4.83 6a1 1 0 0 0 .78.37 1 1 0 0 0 .78-1.63z',
    ],
  },
  'arrowhead-right': {
    viewBox: '0 0 24 24',
    d: [
      'M18.78 11.37l-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z',
      'M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z'
    ],
  },
  'caret-forward-sharp': {
    viewBox: '0 0 512 512',
    d: [
      'M144 448l224-192L144 64v384z'
    ],
  },
  'caret-backward-sharp': {
    viewBox: '0 0 512 512',
    d: [
      'M368 64L144 256l224 192V64z'
    ],
  },

  'instagram': {
    viewBox: '0 0 512 512',
    d: [
      'M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z',
      'M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z'
    ],
    circle: [
      {
        cx: '390.476', cy: '121.524', r: '30.23',
      },
    ],
  },
  'youtube': {
    viewBox: '0 0 512 512',
    d: ['M471.6,147.3c-5.2-19.5-20.4-34.8-39.8-40C396.7,97.8,256,97.8,256,97.8s-140.7,0-175.8,9.5c-19.4,5.2-34.6,20.6-39.8,40 c-9.4,35.3-9.4,109-9.4,109s0,73.7,9.4,109c5.2,19.5,20.4,34.2,39.8,39.4c35.1,9.5,175.8,9.5,175.8,9.5s140.7,0,175.8-9.5 c19.4-5.2,34.6-19.9,39.8-39.4c9.4-35.3,9.4-109,9.4-109S481,182.6,471.6,147.3z M210,323.2V189.4l117.6,66.9L210,323.2L210,323.2z'],
  },
  'facebook': {
    viewBox: '0 0 24 24',
    d: ['M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z'],
  },
  'linkedin': {
    viewBox: '0 0 24 24',
    d: ['M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z'],
  },
  'bicycle': {
    viewBox: '0 0 496.628 496.628',
    d: ['M141.871,417.141c-11.752,9.038-26.278,14.631-42.217,14.631c-38.411,0-69.667-31.256-69.667-69.667 c0-38.419,31.256-69.676,69.667-69.676c28.99,0,53.853,17.825,64.336,43.067l21.816-22.626 c-17.18-29.986-49.118-50.427-86.152-50.427C44.608,262.443,0,307.061,0,362.105c0,55.036,44.608,99.654,99.654,99.654 c25.898,0,49.265-10.122,66.991-26.326c-6.803-2.283-13.256-5.905-18.77-11.215C145.6,422.02,143.637,419.619,141.871,417.141z',
      'M293.191,263.204l-52.769-30.65c23.533-14.729,14.807-9.274,38.341-24.004l-0.741,7.497 c-1.27,12.759,8.133,24.435,19.971,26.834c0.283,0.058,92.635,15.755,96.003,15.755c11.997,0,22.589-8.668,24.617-20.899 c2.265-13.617-6.939-26.484-20.556-28.737l-67.989-11.295l3.233-32.445c3.259-20.733,4.674-27.234-3.067-39.603l-9.732-15.559 c-12.573-20.08-39.084-26.22-59.212-13.618l-106.515,66.689c-26.161,16.38-38.919,50.917-17.561,76.323 c8.344,9.928,89.47,59.781,89.47,59.781l-65.703,68.153c-11.499,11.918-11.157,30.905,0.771,42.394 c5.818,5.611,13.315,8.403,20.811,8.403c7.859,0,15.707-3.064,21.583-9.176l95.564-99.105 C313.192,295.992,310.03,272.984,293.191,263.204z',
      'M405.134,127.072c23.36-14.632,30.437-45.42,15.813-68.769c-14.621-23.369-45.41-30.446-68.767-15.823 c-23.35,14.623-30.427,45.4-15.813,68.749C350.987,134.599,381.776,141.686,405.134,127.072z',
      'M396.973,262.443c-55.619,0-99.654,45.888-99.654,99.662c0,55.036,44.61,99.654,99.654,99.654 c55.034,0,99.655-44.618,99.655-99.654C496.628,307.061,452.007,262.443,396.973,262.443z M396.973,431.772 c-38.411,0-69.667-31.256-69.667-69.667c0-38.419,31.257-69.676,69.667-69.676c38.412,0,69.668,31.257,69.668,69.676 C466.642,400.516,435.385,431.772,396.973,431.772z',],
  },
  cube: {
    viewBox: '0 0 24 24',
    d: [
      'M11.25 11.83L3 8.36v7.73a1.69 1.69 0 0 0 1 1.52L11.19 21h.06z',
      'M12 10.5l8.51-3.57a1.62 1.62 0 0 0-.51-.38l-7.2-3.37a1.87 1.87 0 0 0-1.6 0L4 6.55a1.62 1.62 0 0 0-.51.38z',
      'M12.75 11.83V21h.05l7.2-3.39a1.69 1.69 0 0 0 1-1.51V8.36z'
    ],
  },

};

export default IconCollection;
