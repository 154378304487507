import React, { useState, useEffect } from "react";
// import { useSelector } from 'react-redux';
import LogoImageSrc from '../../assets/img/BeTRITON-black.png';
import LogoSimpleImageSrc from '../../assets/img/logo-simple.png';
import {
  Shadow,
  Wrapper,
  Container,
  Grid,
  Logo,
  LogoSimple,
  Placeholder,
  ButtonLine,
  LogoButton,
} from './header.styled';
import {
  Button,
} from '../../ui-components';
import HeaderMenu from "./header-menu";
// import HeaderBasket from "./header-basket";
import HeaderSocial from './header-social';
import HeaderSubscribe, { SubscribeButton } from "./header-subscribe";
import {
  beLandingURL,
  beLink,
  beLinkType,
} from '../../utils';
import MENU_LIST from './header-menu.json';
import SOCIAL_LIST from './header-social.json';

const STARTING_POS = 50;
const HOMEPAGE_POS = 120;

const isBrowser = () => typeof window !== "undefined"

const Header = ({ home = false, checkout, disableSubscribe = false }) => {
  const [hidden, setHidden] = useState(false);
  const [hash, setHash] = useState(() => isBrowser() && window.location.hash.split("#")[1] || "");
  const [transparent, setTransparent] = useState(home);
  const [menu, setMenu] = useState("none");
  const [order, setOrder] = useState({
    state: '',
    uuid: '',
    items: [],
    itemCount: 0,
    totalPrice: '',
    currency: '',
  })



  const [prevPos, setPrevPos] = useState();

  // const { state, uuid, items, itemCount, totalPrice, currency } = useSelector((state) => ({
  //   state: state.ecommerce.state,
  //   uuid: state.ecommerce.uuid,
  //   items: state.ecommerce.items,
  //   itemCount: state.ecommerce.total_items,
  //   totalPrice: state.ecommerce.total_price,
  //   currency: state.ecommerce.currency,
  // }))

  // useEffect(() => {
  //   if (state) {
  //     setOrder((state) => ({
  //       state: state || '',
  //       uuid: uuid || '',
  //       items: items || [],
  //       itemCount: itemCount || 0,
  //       totalPrice: totalPrice || '',
  //       currency: currency || '',
  //     }))
  //   }

  // }, [state, uuid, items, itemCount, totalPrice, currency])


  useEffect(() => {
    if (home && window.scrollY > HOMEPAGE_POS) {
      setTransparent(false); // so no transparent header after reload;
    }
    setPrevPos(window.scrollY);
  }, [])

  useEffect(() => {
    if (hash === "subscribe") {
      setMenu("subscribe")
    }
  }, [hash])

  useEffect(() => {
    if (menu === "none" && hash === "subscribe") {
      setHash("")
      setMenu("none")
      window.location.hash = "";
    }
  }, [menu])

  const detectScroll = () => {
    const currPos = window.scrollY; //event.target.documentElement.scrollTop;
    const deltaPos = prevPos - currPos;
    const deltaPosPositive = deltaPos < 0 ? deltaPos * -1 : deltaPos;


    if (home && currPos > HOMEPAGE_POS && transparent) {
      setTransparent(false)
    } else if (home && currPos <= HOMEPAGE_POS && !transparent) {
      setTransparent(true)
    }

    if (currPos > STARTING_POS && deltaPosPositive > 4) {
      if (deltaPos < 0) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    } else if (currPos <= STARTING_POS) {
      setHidden(false);
    }
    setPrevPos(currPos);
  };

  const hashChange = (event) => {
    const newHash = window.location.hash.split("#")[1]
    if (hash !== newHash) {
      setHash(newHash);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', detectScroll);
    window.addEventListener('touchmove', detectScroll);
    window.addEventListener('hashchange', hashChange);
    return () => {
      window.removeEventListener('scroll', detectScroll);
      window.removeEventListener('touchmove', detectScroll);
      window.addEventListener('hashchange', hashChange);
    };
  });

  const handleMenu = (nature) => {
    setMenu(nature === menu ? "none" : nature)
  }


  // const { state, uuid, items, itemCount, totalPrice, currency } = order;

  return (
    <>
      {(menu === "none" || menu === "subscribe") ? null : <Shadow />}
      <HeaderSubscribe
        activeMenu={menu}
        toogleVisibility={handleMenu}
      />
      <Wrapper transparent={transparent} hidden={hidden}>
        <Container>
          <Grid container>
            <Grid logo>
              <LogoButton
                href={beLandingURL}
                hideInPhone={false}
              >
                <Logo src={LogoImageSrc} alt="BeTRITON" />
                <LogoSimple src={LogoSimpleImageSrc} alt="BeTRITON" />
              </LogoButton>
            </Grid>
            <Grid space />
            <Grid social>
              <HeaderSocial
                socialList={SOCIAL_LIST}
                openSubscribe={() => { handleMenu("subscribe") }}
              />
            </Grid>
            <Grid basket>
              <ButtonLine container>
                <ButtonLine item>
                  <Button
                    type={beLinkType("app")}
                    to={beLink("app", "/rent")}
                    target="_self"
                    color="primary"
                    size="normal"
                    fullwidth
                  >
                    Rent
                  </Button>
                </ButtonLine>
                <ButtonLine item>
                  <Button
                    type={beLinkType("app")}
                    to={beLink("app", "/preorder")}
                    color="secondary"
                    size="normal"
                    target="_self"
                    fullwidth
                    style={{
                      wordBreak: "keep-all",
                    }}
                  >
                    Pre-Order
                  </Button>
                </ButtonLine>
              </ButtonLine>
              {/*               
              <HeaderBasket
                activeMenu={menu}
                checkout={checkout}
                toogleVisibility={handleMenu}
                order={order}
              >

              </HeaderBasket> */}
            </Grid>
            <Grid menu>
              <HeaderMenu
                activeMenu={menu}
                toogleVisibility={handleMenu}
                menuList={MENU_LIST}
                socialList={SOCIAL_LIST}
                showSubscribe={() => { handleMenu("subscribe") }}
              />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      {/* <Placeholder hidden={home} /> */}
      <Placeholder />
      {!disableSubscribe && menu === "none" && !checkout ? <SubscribeButton showSubscribe={() => { handleMenu("subscribe") }} /> : null}
    </>
  )
}

export default Header;
