import React from 'react';
import {
  Container,
  Header,
  ButtonClose,
  Body,
} from './popup-modal.styled';
import {
  Icon,
} from '../icons';



const PopupModal = (props) => {
  const { title, size, closeAction, children } = props;

  return (
    <Container size={size}>
      <Header>
        {title}
        {closeAction
          ?
          <ButtonClose
            onClick={closeAction}
          >
            <Icon
              name="close-outline"
              size={24}
              noFill
            />
          </ButtonClose>
          : null
        }
      </Header>
      <Body>
        {children}
      </Body>
    </Container>
  )
}

export default PopupModal;
