import React from 'react';
import HeaderToogle from './header-toogle';
import {
  MenuItemList,
  CustomButtonSpan,
  ToogleWrapper,
  ToogleWrapperText,
  SocialWrapper,
  SocialGrid,
} from './header-menu.styled';
import {
  Icon,
  Button,
} from '../../ui-components';
import {
  beLink,
  beLinkType,
} from '../../utils';




const MenuButton = ({ active }) => (
  <ToogleWrapper active={active}>
    <ToogleWrapperText>
      More
    </ToogleWrapperText>
    <Icon
      name="menu"
      size={24}
      noFill
    />
  </ToogleWrapper>
)

const HeaderMenu = ({ activeMenu, toogleVisibility, menuList, socialList, showSubscribe }) => (
  <HeaderToogle
    nature="menu"
    visible={activeMenu === "menu"}
    toogleVisibility={() => toogleVisibility("menu")}
    toogle={<MenuButton active={activeMenu === "menu"} />}
    background="default"
  >
    <MenuItemList container>
      {menuList.map((menu, index) => (
        <MenuItemList
          item
          key={index}
          appearance={menu.appearance}
        >
          <Button
            type={beLinkType(menu.domain)}
            to={beLink(menu.domain, menu.url)}
            target="_self"
            color={menu.color}
            size="menu"
            align="left"
            fullwidth
            nomargin
          >
            <CustomButtonSpan dangerouslySetInnerHTML={{ __html: menu.name }} />
          </Button>
        </MenuItemList>
      ))}
    </MenuItemList>
    <MenuItemList social>
      <SocialWrapper>
        <SocialGrid container>
          {socialList.map((social, index) => (
            <SocialGrid item key={index}>
              <Button
                type="anchor"
                to={social.url}
                target="_blank"
                size="icon_menu"
                icon={social.icon}
                color="white"
                nomargin
                fullwidth
              />
            </SocialGrid>
          ))}
        </SocialGrid>

      </SocialWrapper>
    </MenuItemList>
  </HeaderToogle >
);

export default HeaderMenu;
