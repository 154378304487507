export const strToDate = (str, format = 'short') => {
  // const monthNames = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December"
  // ];

  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const date = new Date(str);
  if (date instanceof Date) {
    switch (format) {
      default:
      case 'short':
        return monthNamesShort[date.getMonth()] + " " + date.getDate();
      case 'full':
        return monthNamesShort[date.getMonth()] + " " + date.getDate() + ', ' + date.getFullYear();

    }


  } else {
    return str;
  }
}



export const markdown2HTML = (str) => {
  const rules = [
    //bold and italics rules
    [/\*\*\s?([^\n]+)\*\*/g, "<b>$1</b>"],
    [/\*\s?([^\n]+)\*/g, "<i>$1</i>"],
    [/__([^_]+)__/g, "<b>$1</b>"],
    [/_([^_`]+)_/g, "<i>$1</i>"],
  ]

  let html = str
  rules.forEach(([rule, template]) => {
    html = html.replace(rule, template)
  })
  html = html.replace( );

  return html;
}
