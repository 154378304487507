import styled, { css } from "styled-components";


const MAX_WIDTH = '460px';
const cutSize = 4;
const cutSize2 = 4;
const btnSize2 = 34;

export const FullScreenShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-y: scroll;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  @media ${({ theme }) => theme.device.mobile}{
    background-color: rgba(0, 0, 0, .75);
  }
`

export const FullScreen = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;

export const FullScreenWrap = styled.div`
  display: table-cell;
  vertical-align: top;
  text-align: center;
  padding: 20px;
`;

export const FullScreenContainer = styled.div`
  position: relative;
  max-width: ${MAX_WIDTH};
  line-height: auto;
  margin: 0 auto;
  margin-top: 10vh;
  @media ${({ theme }) => theme.device.phone}{
    margin-top: 0;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 50%;
  cursor: pointer;
  margin-top: -14px;
  padding: 0;
  border: 0;
  
  z-index: 1;
  background-color: ${({ theme }) => theme.thirdiary.main};
  width: ${btnSize2 - cutSize2 * 2}px;
  height: ${btnSize2}px;
  text-align: center;
  svg{
    margin-top: 4px;
    fill: #fff;
  }
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }
  &:hover{
    background-color: ${({ theme }) => theme.thirdiary.main}};
    
    &::before{
      border-left: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    }
    &::after{
      border-right: ${cutSize}px solid ${({ theme }) => theme.thirdiary.main};
    }
  }
  ${props => props.right && css`
    right: -${cutSize2}px;
  `}
  ${props => props.left && css`
    left: -${cutSize2}px;
    svg {
      margin-left: -6px;
    }
  `}
  ${props => props.close && css`
    right: -${cutSize2}px;
    top: -${cutSize2 * 2}px;
    margin-top: 0;
  `}
`;

export const Container = styled.div`
  padding: 0 20px 10px;
  p{
    line-height: 21px;
  }
`;

export const LogoPlaceholder = styled.div`
  padding-top: 20px;
`;
export const Logo = styled.img`
  max-width: 120px;
`;

export const ButtonPlacement = styled.div`
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
`

