import React from "react";
import IconCollection from "./icon-collection";

const Icon = ({
  name = "",
  style = {},
  fill = "#000",
  viewBox = "",
  width = "100%",
  className = "",
  height = "100%",
  size = "",
  noFill = false
}) => {

  const data = IconCollection[name] === undefined
    ? IconCollection["default"]
    : IconCollection[name];

  const params = {};
  if (!noFill) {
    params["fill"] = fill;
  }

  return (
    <svg
      width={size !== "" ? size : width}
      height={size !== "" ? size : height}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || data.viewBox}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {data.rect
        ? data.rect.map((rect, key) => (<rect key={key} {...params} {...rect} />))
        : ''
      }
      {data.circle
        ? data.circle.map((circle, key) => (<circle key={key} {...params} {...circle} />))
        : ''
      }
      {data.d
        ? data.d.map((element, key) => (<path key={key} {...params} d={element} />))
        : ''
      }
    </svg>
  )
};

export default Icon;
