import React from 'react';
import {
  Wrapper,
  AnchorPoint,
  Anchor,
  AnchorWrapper,
  Container,
  ToggleButton,
} from './header-toogle.styled';
import {
  ZWrapper,
} from '../../ui-components';


const HeaderToogle = ({ nature, toogle, children, visible, toogleVisibility, background }) => (
  <Wrapper>
    <ToggleButton onClick={toogleVisibility}>
      {toogle}
    </ToggleButton>
    <AnchorPoint
      nature={nature}
      active={visible}
    >
      <Anchor nature={nature}>
        <AnchorWrapper>
          <ZWrapper
            size="small"
            color="white"
            shadow
          >
            <Container background={background}>
              {children}
            </Container>
          </ZWrapper>
        </AnchorWrapper>
      </Anchor>
    </AnchorPoint>
  </Wrapper>
);

export default HeaderToogle;
