import styled, { css } from "styled-components";


// https://medium.com/@fionnachan/building-a-react-component-library-with-styled-components-input-field-c79c789387ad
// https://codepen.io/lucasyem/pen/ZEEYKdj

export const Text = styled.p`
  margin: 8px auto;
`

export const Error = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.error.main};
`
export const FormGroup = styled.div`
  position: relative;
  padding: 18px 0 0;
  margin: 16px 0;
`;

const InputStyle = css`
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px dashed ${({ theme }) => theme.shades.line};
  border-color: ${({ theme, error }) => error ? theme.error.light : theme.shades.line};  
  outline: 0;
  font-size: 15px;
  line-height: 21px;
  color: ${({ theme }) => theme.text.primary};
  padding: 7px 0;
  background: transparent;
  padding: 8px 8px;
  background: ${({ theme }) => theme.shades.light};
  transition: border-color 0.2s;

  &:required,&:invalid { box-shadow:none; }

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ label {
    /* top: 20px; */
    font-size: 14px;
    cursor: text;
  }

  &:focus {    
    border-width: 1px;
    border-image: linear-gradient(to right, ${({ theme }) => theme.secondary.main}, ${({ theme }) => theme.primary.main});
    border-image-slice: 1;
  }

  &:focus ~ label {
    transition: 0.2s;
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const SelectField = styled.select`
  ${InputStyle};

  & ~ label{
    /* top: 20px; */
    font-size: 14px;
    cursor: text;
  }

  &:valid ~ label{    
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 13px;
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const InputField = styled.input`
  ${InputStyle};
`;


export const Label = styled.label`
  position: absolute;
  top: 0;
  display: block;
  color: ${({ theme }) => theme.text.secondary};
  transition: 0.2s;
  font-size: 13px;
  padding-left: 8px;

  span{
    color: ${({ theme }) => theme.error.main};
  }

  
`;

